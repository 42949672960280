import { apiDatetimeToDate } from "@warrenio/api-spec/conversion";
import { sortBy } from "remeda";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { ClipBoardTooltip } from "../../components/ClipBoardTooltip.tsx";
import { CurrencyBalance } from "../../components/l10n/Currency.tsx";
import { WTableBody } from "../../components/table/WTable.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { BillingAccountById } from "../accounts/BillingAccountBlock.tsx";
import { DateViewer, Uuid } from "../AdminTable.tsx";
import { AdminTitle } from "../AdminTitle.tsx";
import { UserLink } from "../users/UserLink.tsx";
import { WAdminTable } from "../WAdminTable.tsx";
import { AddProductButton, EditProductButton } from "./AddEditProductModal.tsx";
import { queryAtom } from "./query.ts";

export function CustomBillableProducts() {
    const products = useSuspenseQueryAtom(queryAtom);

    const items = sortBy(products, [(item) => apiDatetimeToDate(item.created_at).getTime(), "desc"]);

    return (
        <>
            <AdminTitle title="Custom Billable Products">
                <AddProductButton button={<WModalButton label="New" color="primary" size="bar" variant="basic" />} />
            </AdminTitle>

            <WAdminTable>
                <thead>
                    <tr>
                        <th>UUID</th>
                        <th>User ID</th>
                        <th>BA ID</th>
                        <th>Description</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Creator User ID</th>
                        <th>Unit</th>
                        <th />
                    </tr>
                </thead>
                <WTableBody>
                    {items.map((item) => {
                        const {
                            uuid,
                            user_id,
                            billing_account_id,
                            description,
                            created_at,
                            updated_at,
                            start_date,
                            end_date,
                            creator_user_id,
                            price_per_unit,
                            qty_unit,
                        } = item;

                        return (
                            <tr key={uuid}>
                                <td>
                                    <ClipBoardTooltip isHtml hideIcon>
                                        <Uuid value={uuid} />
                                    </ClipBoardTooltip>
                                </td>
                                <td>
                                    <UserLink value={user_id} />
                                </td>
                                <td>
                                    <BillingAccountById value={billing_account_id} />
                                </td>
                                <td>{description}</td>
                                <td>
                                    <DateViewer value={apiDatetimeToDate(created_at)} />
                                </td>
                                <td>{updated_at ? <DateViewer value={apiDatetimeToDate(updated_at)} /> : "-"}</td>
                                <td>{start_date ? <DateViewer value={apiDatetimeToDate(start_date)} /> : "-"}</td>
                                <td>{end_date ? <DateViewer value={apiDatetimeToDate(end_date)} /> : "-"}</td>
                                <td>{creator_user_id ?? "-"}</td>
                                <td>
                                    <CurrencyBalance value={price_per_unit} /> / {qty_unit}
                                </td>
                                <td className="text-right">
                                    <div className="flex gap-0.5em justify-end">
                                        <EditProductButton
                                            item={item}
                                            button={<WModalButton label="Edit" inTable icon="jp-icon-edit" />}
                                        />
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </WTableBody>
            </WAdminTable>
            <div className="p-2">
                <AddProductButton
                    button={
                        <WModalButton
                            className="w-full"
                            label="Add Custom Billable Product"
                            color="primary"
                            size="lg"
                            variant="dashed"
                            icon="jp-icon-add"
                        />
                    }
                />
            </div>
        </>
    );
}
